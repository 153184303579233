













import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const sessionModule = namespace('session')

@Component
export default class ThanksForBuyingTicket extends Vue {
  @sessionModule.Getter state!: Record<string, string>
}
